<template>
  <div :class="['the-tabs', parentPage.color]">
    <div class="tabs-wrapper">
      <div v-if="tabPage" v-html="tabPage.html"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "PageList",
  data() {
    return {
      pages: this.$store.pages
    };
  },
  components: {},
  computed: {
    parentPage() {
      // console.log(this.$route.params.slug[0]);
      return this.pages.find(page => page.slug === this.$route.params.slug);
    },
    childPage() {
      return this.parentPage.subPages.find(
        page => page.slug === this.$route.params.subSlug
      );
    },
    tabPage() {
      return this.childPage.tabs.find(
        tab => tab.slug === this.$route.params.tabSlug
      );
    }
  }
};
</script>

<style lang="scss">
.page {
  list-style-type: none;
  margin: 0;
  padding: 0;

  .featured-image {
    max-width: 15em;
    margin: 0.5em auto;
  }
}
</style>
